<template>
  <v-container fluid class="pa-0">
    <v-row dense>
      <!-- Search Input-->
      <v-col cols="12" :class="{p1440: org === 'p1440'}">
        <v-text-field
          ref="search"
          label="Membership Lookup"
          v-model="term"
          :hint="instructions || 'Search by name, email, or membership number'"
          placeholder="Start typing to search"
          persistent-hint
          color="color3"
          append-icon="fas fa-search"
          autocomplete="off"
          type="search"
          :loading="searching"
          :solo-inverted="org !== 'p1440'"
          :solo="org === 'p1440'"
          clearable
          autofocus
        ></v-text-field>
      </v-col>
      <!-- Data Table -->
      <v-col cols="12" v-if="!selector">
        <v-data-table
          :headers="headers"
          :items="searchResults"
          class="elevation-1"
          :options.sync="pagination"
          :footer-props="{itemsPerPageOptions: [10, 20, 50, -1]}"
          :loading="searching"
        >
          <template v-slot:[`item.status`]="{ item }">
              <v-chip
                :color="`${item.status === 'Active' ? 'success' : 'error'} white--text`"
              >{{item.status}}</v-chip>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left v-if="!$vuetify.breakpoint.xsOnly">
              <span>View {{item.firstName}}'s profile</span>
              <template v-slot:activator="{on}">
                <v-btn
                  color="color3 color3Text--text"
                  small
                  fab
                  v-on="on"
                  :to="{
                    name: 'member-profile',
                    params: {
                      id: item.id,
                      playerProfileId: item.playerProfileId,
                      memberId: item.id,
                      name: `${item.firstName} ${item.lastName}`,
                      profilePic: item.profilePic
                    }
                  }"
                >
                  <v-icon small>fas fa-caret-right</v-icon>
                </v-btn>
              </template>
            </v-tooltip>
            <v-btn
              color="color3 color3Text--text"
              small
              text
              v-else
              :to="{
                name: 'member-profile',
                params: {
                  id: item.id,
                  playerProfileId: item.playerProfileId,
                  memberId: item.id,
                  name: `${item.firstName} ${item.lastName}`,
                  profilePic: item.profilePic
                }
              }"
            >
              View {{item.firstName}}'s profile <v-icon class="ml-3">fas fa-caret-right</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
      <!-- Data List -->
      <v-col cols="12" v-else>
        <v-expand-transition>
          <v-list three-line v-if="results">
            <template v-for="(r, i) in searchResults">
              <search-result-item
                :key="r.id"
                :player="r"
                :showId="true"
                @click="selectProfile"
              ></search-result-item>
              <v-divider :key="`${r.id}-div`" v-if="(i + 1) !== searchResults.length"></v-divider>
            </template>
          </v-list>
        </v-expand-transition>
        <v-expand-transition>
          <div v-if="confirm && firstResult" class="text-center">
            <v-btn class="mt-3" color="color3 color3Text--text" @click.stop="selectProfile(firstResult)">Continue with {{firstResult.fullName}}</v-btn>
          </div>
        </v-expand-transition>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import debounce from 'lodash.debounce'
import { mapGetters } from 'vuex'
import SearchResultItem from '@/components/Player/Search/SearchResultItem'
export default {
  props: ['active', 'autoSearch', 'selector', 'instructions', 'username'],
  data () {
    return {
      searching: false,
      term: null,
      searchResults: [],
      pagination: { itemsPerPage: 10 },
      headers: [
        { text: 'Member #', value: 'id', align: 'left' },
        { text: 'First Name', value: 'firstName', align: 'left' },
        { text: 'Last Name', value: 'lastName', align: 'left' },
        { text: 'City', value: 'city', align: 'left' },
        { text: 'State', value: 'state', align: 'left' },
        { text: 'Status', value: 'status', align: 'center' },
        { text: '', sortable: false, value: 'actions', align: 'right' }
      ],
      selectedProfile: null,
      loading: false,
      confirm: false
    }
  },
  computed: {
    ...mapGetters(['subdomain']),
    org () {
      return this.username || this.subdomain
    },
    results () {
      return this.searchResults.length
    },
    firstResult () {
      return this.searchResults[0]
    }
  },
  methods: {
    reset () {
      this.selectedProfile = null
      this.term = null
      this.searchResults = []
      this.confirm = false
    },
    selectProfile (profile) {
      this.selectedProfile = profile
      this.$emit('profile-selected', profile)
    },
    search: debounce(function () {
      this.doSearch()
    }, 300),
    doSearch () {
      if (!this.term || typeof this.term !== 'string' || this.term.length < 3) {
        this.searchResults = []
        return
      }
      this.searching = true
      this.$VBL.get.members(this.org, this.term)
        .then(r => {
          this.searchResults = r.data
          this.tryAutoSelect()
        })
        .catch(e => console.log(e))
        .finally(() => {
          this.searching = false
        })
    },
    tryAutoSelect () {
      if (this.selector && this.searchResults.length === 1) {
        if (this.term.toLowerCase() === this.searchResults[0].id.toLowerCase()) {
          this.selectProfile(this.searchResults[0])
        } else {
          this.confirm = true
        }
      }
    }
  },
  watch: {
    term: 'search',
    active: function (val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.search.focus()
        })
      } else {
        this.reset()
      }
    }
  },
  mounted () {
    if (this.autoSearch) {
      this.term = this.autoSearch
      this.search()
    }
  },
  components: {
    SearchResultItem
  }
}
</script>

<style scoped>
>>> .p1440 .v-messages {
  color: white !important
}
</style>
