<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12" class="title">
        Member Search
      </v-col>
      <v-col cols="12" class="subheading">
        Simply search for your membership by entering your number or name.
      </v-col>
      <member-search></member-search>
    </v-row>
  </v-container>
</template>

<script>
import MemberSearch from '@/components/Membership/MemberSearch'
export default {
  components: {
    MemberSearch
  }
}
</script>

<style scoped>

</style>
