var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{class:{p1440: _vm.org === 'p1440'},attrs:{"cols":"12"}},[_c('v-text-field',{ref:"search",attrs:{"label":"Membership Lookup","hint":_vm.instructions || 'Search by name, email, or membership number',"placeholder":"Start typing to search","persistent-hint":"","color":"color3","append-icon":"fas fa-search","autocomplete":"off","type":"search","loading":_vm.searching,"solo-inverted":_vm.org !== 'p1440',"solo":_vm.org === 'p1440',"clearable":"","autofocus":""},model:{value:(_vm.term),callback:function ($$v) {_vm.term=$$v},expression:"term"}})],1),(!_vm.selector)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.searchResults,"options":_vm.pagination,"footer-props":{itemsPerPageOptions: [10, 20, 50, -1]},"loading":_vm.searching},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":((item.status === 'Active' ? 'success' : 'error') + " white--text")}},[_vm._v(_vm._s(item.status))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!_vm.$vuetify.breakpoint.xsOnly)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"color3 color3Text--text","small":"","fab":"","to":{
                  name: 'member-profile',
                  params: {
                    id: item.id,
                    playerProfileId: item.playerProfileId,
                    memberId: item.id,
                    name: ((item.firstName) + " " + (item.lastName)),
                    profilePic: item.profilePic
                  }
                }}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-caret-right")])],1)]}}],null,true)},[_c('span',[_vm._v("View "+_vm._s(item.firstName)+"'s profile")])]):_c('v-btn',{attrs:{"color":"color3 color3Text--text","small":"","text":"","to":{
              name: 'member-profile',
              params: {
                id: item.id,
                playerProfileId: item.playerProfileId,
                memberId: item.id,
                name: ((item.firstName) + " " + (item.lastName)),
                profilePic: item.profilePic
              }
            }}},[_vm._v(" View "+_vm._s(item.firstName)+"'s profile "),_c('v-icon',{staticClass:"ml-3"},[_vm._v("fas fa-caret-right")])],1)]}}],null,true)})],1):_c('v-col',{attrs:{"cols":"12"}},[_c('v-expand-transition',[(_vm.results)?_c('v-list',{attrs:{"three-line":""}},[_vm._l((_vm.searchResults),function(r,i){return [_c('search-result-item',{key:r.id,attrs:{"player":r,"showId":true},on:{"click":_vm.selectProfile}}),((i + 1) !== _vm.searchResults.length)?_c('v-divider',{key:((r.id) + "-div")}):_vm._e()]})],2):_vm._e()],1),_c('v-expand-transition',[(_vm.confirm && _vm.firstResult)?_c('div',{staticClass:"text-center"},[_c('v-btn',{staticClass:"mt-3",attrs:{"color":"color3 color3Text--text"},on:{"click":function($event){$event.stopPropagation();return _vm.selectProfile(_vm.firstResult)}}},[_vm._v("Continue with "+_vm._s(_vm.firstResult.fullName))])],1):_vm._e()])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }